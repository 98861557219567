// External imports
import {Route, Routes, useLocation} from "react-router-dom";

// Local imports
import {observer} from "mobx-react";
import {useEffect} from "react";
import Header from "../components/Header";
import Logo from "../components/Logo";
import MainMenu from "../components/MainMenu";
import Footer from "../components/MainPageBlocks/Footer";
import Main from "../components/MainPageBlocks/Main";
import Page from "../components/MainPageBlocks/Page";
import PrivateRoute from "../components/PrivateRouter";
import {PageslInfo} from "../constants";
import {PagesId} from "../constants/PAGES_ID";
import ErrorPage from "../pages/Error";
import {useRootStore} from "../store/RootStore";

const App: React.FC = observer(() => {
    const {userStore, routeStore} = useRootStore();
    const location = useLocation();

    useEffect(() => {
        userStore.checkAuth();
    }, []);

    useEffect(() => {
        console.log(location.pathname);
        routeStore.setActiveRoute(location.pathname);
    }, [location]);

    return (
        <Page>
            <Header logoComponent={<Logo />} menuComponent={<MainMenu />} />
            <Main>
                <Routes>
                    {PageslInfo.filter(item => !item.disabled && item.id !== PagesId.Error).map(
                        item => (
                            <Route
                                key={item.id}
                                path={`/${item.path}`}
                                element={
                                    item.isPrivate ? (
                                        <PrivateRoute>
                                            {item.el ? <item.el /> : <ErrorPage />}
                                        </PrivateRoute>
                                    ) : item.el ? (
                                        <item.el />
                                    ) : (
                                        <ErrorPage />
                                    )
                                }
                            />
                        )
                    )}
                    {/* <Route path="/" element={<HomePage />} />
                    <Route path="login" element={<LoginPage />} />
                     */}
                    <Route path="*" element={<ErrorPage />} />
                </Routes>
            </Main>
            <Footer />
        </Page>
    );
});

// Default export
export default App;
