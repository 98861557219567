import React, {ReactNode} from "react";

interface FooterProps {
    children?: ReactNode;
}

const Footer: React.FC<FooterProps> = ({children}) => {
    return (
        <div className="w-full bg-blue-500 text-white text-center bg-gray-100">
            {children ? children : null}
        </div>
    );
};

export default Footer;
