export type ClientValidationRule =
    | "isValidEmail"
    | "isValidMinLength"
    | "isValidMaxLength"
    | "hasUppercase"
    | "isAlphaNumeric"
    | "hasNumeric"
    | "containsSymbol"
    | "isSameValues"
    | "isValidPhone"
    | "isValidDate"
    | "isNotFutureDate"
    | "isValidFileType"
    | "isNotGreaterThan"
    | "isNotLessThan"
    | "isValidFileSize";

export const ClientValidationService: Record<ClientValidationRule, (...args: any[]) => boolean> = {
    isValidEmail: (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    },
    isValidMinLength: (str: string, minLength: number): boolean => {
        return str.length >= minLength;
    },
    isValidMaxLength: (str: string, maxLength: number): boolean => {
        return str.length <= maxLength;
    },
    hasUppercase: (str: string): boolean => {
        return /[A-Z]/.test(str);
    },
    isAlphaNumeric: (str: string): boolean => {
        const regex = /^[A-Za-z0-9]+$/;
        return regex.test(str);
    },
    hasNumeric: (str: string): boolean => {
        return /[0-9]/.test(str);
    },
    containsSymbol: (str: string, symbols: string): boolean => {
        const symbolRegex = new RegExp(`[${symbols.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&")}]`);
        return symbolRegex.test(str);
    },
    isValidPhone: (phone: string): boolean => {
        const phoneRegex =
            /^(\+?\d{1,3})?[-.\s]?(\(?\d{1,4}\)?)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
        return phoneRegex.test(phone);
    },
    isValidDate: (value: Date | string | undefined | null): boolean => {
        if (value) {
            const date = new Date(value);
            return !isNaN(date.getTime());
        }
        return false;
    },
    isNotFutureDate: (value: Date | string): boolean => {
        const today = new Date();
        const date = new Date(value);
        return !(date > today);
    },
    isSameValues: (value1: string, value2: string): boolean => {
        return value1 === value2;
    },
    isValidFileType: (value: File[], types: string[]): boolean => {
        if (!value) return false;
        const file = value[0];
        return types.includes(file.type);
    },
    isValidFileSize: (value: File[], size: number): boolean => {
        if (!value) return false;
        const file = value[0];
        return file.size <= size;
    },
    isNotGreaterThan: (value: number, max: number): boolean => {
        return value <= max;
    },
    isNotLessThan: (value: number, min: number): boolean => {
        return value >= min;
    }
};

/*export const ClientValidationService: {
    [key in ClientValidationRule]: (...args: any[]) => boolean;
} = {
...
};*/
