import {observer} from "mobx-react";
import {TicketSearchResult} from "../../services/interfaces";
import {TicketCard} from "./TicketCard";
import {classNames} from "primereact/utils";

type TicketCardCollectionProps = {
    className?: string;
    ticketsSearchResult: TicketSearchResult[];
};

type TicketGroupProps = {
    className?: string;
    ticketSearchResult: TicketSearchResult;
};

export const TicketsCardGroup = observer(({className, ticketSearchResult}: TicketGroupProps) => {
    return (
        <div className={"tickets-card-group"}>
            <div className="tickets-card-group__header">
                <h4 className={"header4"}>{ticketSearchResult.sortData.value}</h4>
            </div>
            <div className="tickets-card-group__content">
                {ticketSearchResult.data.map((ticketData, i) => (
                    <TicketCard key={`ticket-${i}`} data={ticketData} />
                ))}
            </div>
        </div>
    );
});

export const TicketCardCollection = observer(
    ({className, ticketsSearchResult}: TicketCardCollectionProps) => {
        return (
            <div className={classNames("ticket-card-collection w-full", className)}>
                {ticketsSearchResult.map((tickets, i) => (
                    <TicketsCardGroup key={`tickets-group-${i}`} ticketSearchResult={tickets} />
                ))}
            </div>
        );
    }
);
