/* eslint-disable max-len */
import {Password} from "primereact/password";
import {classNames} from "primereact/utils";
import {ChangeEvent, ReactNode} from "react";
import {Controller} from "react-hook-form";
import {Suggestion} from "../../customHooks/usePasswordSuggesions";
import {Field} from "./FieldComponents";
import {FieldError, FieldErrorMessage} from "./FieldErrorComponents";

type PasswordFieldControlledProps = {
    disabled?: boolean;
    name: string;
    control: any;
    errors?: {message?: string};
    type?: string;
    placeholder?: string;
    footer?: ReactNode;
    rulles?: object;
    customOnChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const PasswordFieldControlled = ({
    name,
    disabled,
    errors,
    control,
    type,
    placeholder = "Password",
    rulles,
    customOnChange,
    footer
}: PasswordFieldControlledProps) => {
    const contnet = <></>;

    return (
        <Field>
            <Controller
                name={name}
                control={control}
                rules={rulles}
                render={({field}) => (
                    <Password
                        placeholder={placeholder}
                        className={classNames("w-full p-fluid")}
                        disabled={disabled}
                        toggleMask
                        type={type}
                        aria-errormessage={`${name}-error`}
                        invalid={errors && errors.message ? true : false}
                        id={field.name}
                        content={contnet}
                        footer={footer}
                        {...field}
                        onChange={e => {
                            field.onChange(e);
                            customOnChange && customOnChange(e);
                        }}
                    />
                )}
            />
            {errors ? (
                <FieldError id={`${name}-error`}>
                    <FieldErrorMessage errors={errors.message} />
                </FieldError>
            ) : null}
        </Field>
    );
};

export const PasswordSuggestionsFooter = ({rules}: {rules?: Suggestion[]}) => (
    <div>
        <ul className="suggestions-list pl-2 ml-2 mt-0 pb-0 line-height-3">
            {rules &&
                rules.map((rule, index) => (
                    <li key={index}>
                        {rule.valid ? "✔️" : "❌"} {rule.message}
                    </li>
                ))}
        </ul>
    </div>
);
