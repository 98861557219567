import {action, makeObservable, observable} from "mobx";
import {ITicketSearchData, TicketSearchResult} from "../../services/interfaces";
import {RootStore} from "../RootStore";
import {DEFAULT_AIRPORT_FROM, DEFAULT_AIRPORT_TO} from "../../constants/AIRPORTS";

// const defaultSearchParams: ITicketSearchData = {
//     airportFrom: DEFAULT_AIRPORT_FROM,
//     airportTo: DEFAULT_AIRPORT_TO,
//     passengers: undefined,
//     dates: [new Date(), null]
// };

const defaultSearchParams: ITicketSearchData = {
    airportFrom: undefined,
    airportTo: undefined,
    passengers: "",
    dates: undefined
};

export class SearchTicketsStore {
    public searchResult: TicketSearchResult[] = [];

    public ticketsSearchParams: ITicketSearchData = defaultSearchParams;

    constructor(protected rootStore: RootStore) {
        makeObservable(this, {
            searchResult: observable,
            ticketsSearchParams: observable,
            setSearchResult: action,
            setTicketsSearchParams: action
        });
    }

    public setSearchResult(searchResult: TicketSearchResult[]) {
        this.searchResult = searchResult;
    }

    public reset() {
        this.setSearchResult([]);
        this.setTicketsSearchParams(defaultSearchParams);
    }

    setTicketsSearchParams(ticketsSearchParams: ITicketSearchData) {
        this.ticketsSearchParams = ticketsSearchParams;
    }
}
