// Local imports
import {Card} from "primereact/card";
import {TicketsSearchForm} from "../../components/forms/TicketsSearchForm";
import PageContent from "../../components/MainPageBlocks/PageContent";
import {PagesId} from "../../constants/PAGES_ID";
import {TicketCard} from "../../components/cards";
import {observer} from "mobx-react";
import {useRootStore} from "../../store/RootStore";
import {useNavigate} from "react-router-dom";

const pageId = PagesId.Home;

const HomePage = observer(() => {
    const {searchTicketsStore} = useRootStore();
    searchTicketsStore.reset();
    const navigate = useNavigate();

    return (
        <PageContent className={"page-home"} pageId={pageId}>
            <div className="welcome-block">
                <h1 className="header1">Reach your destination all over the world.</h1>
                <h3 className="header3">
                    Cheapest way to enjoy business class air jet in your journey.
                </h3>
                <img
                    className="w-11 welcome-block__image mx-auto"
                    src={"../images/jet-xl.png"}
                    alt={"Jetsaver"}
                />
            </div>

            <TicketsSearchForm
                onSuccessSearch={data => {
                    searchTicketsStore.setSearchResult(data);
                    navigate("/search");
                }}
            />
        </PageContent>
    );
});

// Default export
export default HomePage;
