import PageContent from "../../components/MainPageBlocks/PageContent";
import {PagesId} from "../../constants/PAGES_ID";

const pageId = PagesId.Support;

function SupportPage() {
    return (
        <PageContent pageId={pageId}>
            <h1>Support</h1>
        </PageContent>
    );
}

export default SupportPage;
