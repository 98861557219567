import {ProfilePersonalInfoForm} from "../../components/forms/ProfilePersonalInfoForm";
import PageContent from "../../components/MainPageBlocks/PageContent";
import {PagesId} from "../../constants/PAGES_ID";

const pageId = PagesId.Account;

function AccountPage() {
    return (
        <PageContent pageId={pageId}>
            <ProfilePersonalInfoForm />
        </PageContent>
    );
}

export default AccountPage;
