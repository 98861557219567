/* eslint-disable max-len */
import {Calendar} from "primereact/calendar";
import {InputText} from "primereact/inputtext";
import {classNames} from "primereact/utils";
import {Controller, UseFormClearErrors, UseFormRegister} from "react-hook-form";
import {FieldError, FieldErrorMessage} from "./FieldErrorComponents";
import {Checkbox} from "primereact/checkbox";
import {ReactComponent as CheckBoxIcon} from "../../icons/icon-checkbox.svg";
import {FileUpload} from "primereact/fileupload";
import {FormEvent, RefObject, useRef, useState} from "react";
import {ReactComponent as FormUserPickIcon} from "../../icons/icon-form-userpick.svg";
import {ContentB1} from "../common";
import {Dropdown} from "primereact/dropdown";
import {FloatLabel} from "primereact/floatlabel";
import {AutoComplete} from "primereact/autocomplete";
import {AirportData} from "../../services/interfaces";
import {KeyFilterType} from "primereact/keyfilter";

export type FieldProps = {
    className?: string;
    children?: React.ReactNode;
    baseClassName?: string;
    id?: string;
};

export const Field = ({baseClassName = "field col-12 p-0", className, children}: FieldProps) => {
    return <div className={classNames(baseClassName, className)}>{children ? children : null}</div>;
};

type TextFieldProps = {
    id: string;
    register?: UseFormRegister<any>;
    errors?: {message?: string};
    type?: string;
    placeholder?: string;
    disabled?: boolean;
    additionalErrorComponent?: React.ReactNode; // TODO FIXME need crate better
    rulles?: object;
    value?: string;
};

export const TextField = ({
    id,
    register,
    placeholder,
    errors,
    type,
    disabled,
    rulles,
    value,
    additionalErrorComponent
}: TextFieldProps) => {
    return (
        <Field>
            <InputText
                className={classNames("w-full", "p-fluid")}
                placeholder={placeholder}
                id={id}
                {...(register ? (rulles ? register(id, rulles) : register(id)) : {})}
                type={type}
                disabled={disabled}
                invalid={errors && errors.message ? true : false}
                aria-errormessage={`${id}-error`}
                value={value}
            />
            {errors ? (
                <FieldError id={`${id}-error`}>
                    <FieldErrorMessage errors={errors.message} />
                    {additionalErrorComponent ? additionalErrorComponent : null}
                </FieldError>
            ) : null}
        </Field>
    );
};

type TextFieldControlledProps = {
    disabled?: boolean;
    name: string;
    control: any;
    keyfilter?: KeyFilterType;
    errors?: {message?: string};
    type?: string;
    placeholder?: string;
    rulles?: object;
    additionalErrorComponent?: React.ReactNode; // TODO FIXME need crate better
};

export const TextFieldControlled = ({
    name,
    control,
    placeholder,
    errors,
    type,
    disabled,
    rulles,
    keyfilter,
    additionalErrorComponent
}: TextFieldControlledProps) => {
    return (
        <Field>
            <Controller
                name={name}
                control={control}
                rules={rulles}
                render={({field}) => (
                    <InputText
                        className={classNames("w-full", "p-fluid")}
                        placeholder={placeholder}
                        id={field.name}
                        type={type}
                        disabled={disabled}
                        invalid={errors && errors.message ? true : false}
                        aria-errormessage={`${name}-error`}
                        keyfilter={keyfilter}
                        {...field}
                    />
                )}
            />
            {errors ? (
                <FieldError id={`${name}-error`}>
                    <FieldErrorMessage errors={errors.message} />
                    {additionalErrorComponent ? additionalErrorComponent : null}
                </FieldError>
            ) : null}
        </Field>
    );
};

type CalendarControlledProps = {
    dateFormat?: string;
    showIcon?: boolean;
    minDate?: Date;
    numberOfMonths?: number;
    readOnlyInput?: boolean;
    selectionMode?: "range" | "single" | "multiple";
    showButtonBar?: boolean;
    hideOnRangeSelection?: boolean;
    //
    name: string;
    control: any;
    errors?: {message?: string};
    readonlyInput?: boolean;
    placeholder?: string;
    rulles?: object;
    disabled?: boolean;
    additionalErrorComponent?: React.ReactNode;
};

export const CalendarControlled = ({
    name,
    control,
    placeholder,
    errors,
    disabled,
    readonlyInput,
    rulles,
    additionalErrorComponent,
    dateFormat = "dd/mm/yy",
    numberOfMonths = 1,
    showIcon = false,
    minDate,
    selectionMode = "single",
    showButtonBar = false,
    hideOnRangeSelection = false
}: CalendarControlledProps) => {
    return (
        <Field>
            <Controller
                name={name}
                control={control}
                rules={rulles}
                render={({field}) => (
                    <Calendar
                        className={"w-full p-fluid"}
                        id={field.name}
                        value={field.value}
                        onChange={(e: any) => field.onChange(e.value)}
                        placeholder={placeholder}
                        disabled={disabled}
                        readOnlyInput={readonlyInput}
                        invalid={errors && errors.message ? true : false}
                        dateFormat={dateFormat}
                        numberOfMonths={numberOfMonths}
                        showIcon={showIcon}
                        minDate={minDate}
                        selectionMode={selectionMode}
                        showButtonBar={showButtonBar}
                        hideOnRangeSelection={hideOnRangeSelection}
                        // touchUI={true}
                    />
                )}
            />
            {errors ? (
                <FieldError id={`${name}-error`}>
                    <FieldErrorMessage errors={errors.message} />
                    {additionalErrorComponent ? additionalErrorComponent : null}
                </FieldError>
            ) : null}
        </Field>
    );
};

type CheckboxControlledProps = {
    disabled?: boolean;
    name: string;
    control: any;
    label?: React.ReactNode;
};

export const CheckboxControlled = ({name, control, disabled, label}: CheckboxControlledProps) => {
    return (
        <div className="field-checkbox flex align-items-center">
            <Controller
                name={name}
                control={control}
                render={({field}) => (
                    <Checkbox
                        icon={<CheckBoxIcon />}
                        inputId={field.name}
                        onChange={e => field.onChange(e.checked)}
                        checked={field.value}
                        disabled={disabled}
                    />
                )}
            />
            {label ? label : null}
        </div>
    );
};

type UploadImageControlledProps = {
    name: string;
    control: any;
    disabled?: boolean;
    errors?: {message?: string};
    type?: string;
    placeholder?: string;
    clearErrors?: UseFormClearErrors<any>;
    rulles?: object;
    additionalErrorComponent?: React.ReactNode; // TODO FIXME need crate better
};

export const UploadImageControlled = ({
    name,
    control,
    errors,
    rulles,
    additionalErrorComponent,
    disabled,
    clearErrors
}: UploadImageControlledProps) => {
    //
    const fileUploadRef = useRef<FileUpload>(null);
    const chooseLabel = "Upload";
    //

    return (
        <Field baseClassName={"field col-12"} className={"field-image-upload"}>
            <FormUserPickIcon />
            <div className="content-b3 mt-3">
                Please upload your <span className="content-b1">Document ID photo</span>
            </div>
            <Controller
                name={name}
                control={control}
                rules={rulles}
                render={({field, fieldState}) => (
                    <FileUpload
                        className={"mt-3"}
                        ref={fileUploadRef}
                        mode="basic"
                        name={name}
                        accept="image/*"
                        customUpload
                        auto
                        chooseLabel={chooseLabel}
                        onSelect={e => {
                            field.onChange(e.files);

                            console.log(name);
                            // clearErrors && clearErrors(name);
                        }}
                        onClear={() => {
                            field.onChange(null);
                            // clearErrors && clearErrors(name);
                        }}
                        disabled={disabled}
                        onValidationFail={() => {
                            console.error("on validation fail");
                        }}
                        onError={() => {
                            console.error("on error");
                        }}
                    />
                )}
            />
            {errors ? (
                <FieldError
                    baseClassName={
                        "flex justify-content-center w-full align-items-center p-informer"
                    }
                    id={`${name}-error`}
                >
                    <FieldErrorMessage errors={errors.message} />
                    {additionalErrorComponent ? additionalErrorComponent : null}
                </FieldError>
            ) : null}
        </Field>
    );
};

type DropdownFieldControlledProps = TextFieldControlledProps & {
    optionName?: string;
    value?: any;
    options?: any[];
};

///
export const DropdownFieldControlled = ({
    name,
    control,
    placeholder,
    errors,
    type,
    disabled,
    rulles,
    options,
    value,
    optionName,
    additionalErrorComponent
}: DropdownFieldControlledProps) => {
    return (
        <Field>
            <Controller
                name={name}
                control={control}
                rules={rulles}
                render={({field}) => (
                    <Dropdown
                        className={classNames("w-full", "p-fluid")}
                        placeholder={placeholder}
                        id={field.name}
                        value={field.value}
                        type={type}
                        onChange={e => field.onChange(e.value)}
                        invalid={errors && errors.message ? true : false}
                        aria-errormessage={`${name}-error`}
                        disabled={disabled}
                        options={options}
                        optionLabel={optionName}
                    />
                )}
            />
            {errors ? (
                <FieldError id={`${name}-error`}>
                    <FieldErrorMessage errors={errors.message} />
                    {additionalErrorComponent ? additionalErrorComponent : null}
                </FieldError>
            ) : null}
        </Field>
    );
};

type AutocompleteFieldControlledProps = TextFieldControlledProps & {
    dropdown?: boolean;
    suggestions?: AirportData[];
    fieldName: string;
    createNew: (input: string) => AirportData;
};

export const AutocompleteAirportFieldControlled = ({
    name,
    control,
    placeholder,
    errors,
    type,
    disabled,
    rulles,
    additionalErrorComponent,
    fieldName,
    dropdown,
    createNew,
    suggestions = []
}: AutocompleteFieldControlledProps) => {
    const [filteredSuggestions, setFilteredSuggestions] = useState<AirportData[]>([]);
    const [inputText, setInputText] = useState<string | null>(null);

    const hasNumbers = (val: string) => {
        return /\d/.test(val);
    };

    const searchSuggestions = (event: {query: string}) => {
        const query = event.query.trim();

        if (hasNumbers(query)) {
            setFilteredSuggestions([]); // Очищаем список предложений
            return;
        }

        const filtered = suggestions.filter(airport =>
            airport.shortName.toLowerCase().includes(query.toLowerCase())
        );
        setInputText(event.query);
        if (filtered.length === 0 && createNew && query !== "") {
            const newSuggestion: AirportData = createNew(query);
            setFilteredSuggestions([newSuggestion]);
        } else {
            setFilteredSuggestions(filtered);
        }
    };

    return (
        <Field>
            <Controller
                name={name}
                control={control}
                rules={rulles}
                render={({field}) => (
                    <AutoComplete
                        className={classNames("w-full", "p-fluid")}
                        placeholder={placeholder}
                        id={field.name}
                        type={type}
                        disabled={disabled}
                        invalid={errors && errors.message ? true : false}
                        aria-errormessage={`${name}-error`}
                        dropdown={dropdown}
                        suggestions={filteredSuggestions}
                        completeMethod={searchSuggestions}
                        field={fieldName}
                        value={field.value ? field.value[fieldName] : inputText}
                        onChange={e => {
                            const inputValue = e.value;

                            if (hasNumbers(inputValue)) {
                                return;
                            }

                            if (!inputValue) {
                                field.onChange(null);
                                setInputText("");
                            } else if (typeof inputValue === "string") {
                                const newAirport = createNew(inputValue);
                                field.onChange(newAirport);
                            } else if (typeof inputValue === "object") {
                                field.onChange(inputValue);
                            }
                        }}

                        // {...field}
                    />
                )}
            />
            {errors ? (
                <FieldError id={`${name}-error`}>
                    <FieldErrorMessage errors={errors.message} />
                    {additionalErrorComponent ? additionalErrorComponent : null}
                </FieldError>
            ) : null}
        </Field>
    );
};
