import {ENV_CONST} from "../constants";
import {WaitAction} from "../utils/WaitAction";
import {
    ILandingLoginData,
    ILandingLoginResponse,
    ILoginData,
    ILoginResponse,
    INewPasswordResponse,
    IProfilePasswordData,
    IProfilePersonalID,
    IProfilePersonalInfoData,
    IProfilePersonalInfoResponse,
    IResetPasswordResponse,
    ISetGeneralUserInfoResponse,
    IUserGeneralInfo
} from "./interfaces";

const {IS_DEV_FRONTEND_MODE, API_HOST} = ENV_CONST;

interface IServerAPI {
    checkAuth: () => Promise<boolean>;
    resetPassword: (resetEmail: string) => Promise<IResetPasswordResponse>;
    login: (params: ILoginData) => Promise<ILoginResponse>;
    setNewPass: (oldPass: string, newPass: string) => Promise<INewPasswordResponse>;
    changePass: (data: IProfilePasswordData) => Promise<INewPasswordResponse>;
    setUserGeneralInfo: (params: IUserGeneralInfo) => Promise<ISetGeneralUserInfoResponse>;
    setPersonalInfo: (params: IProfilePersonalInfoData) => Promise<IProfilePersonalInfoResponse>;
    setPersonalID: (params: IProfilePersonalID) => Promise<IProfilePersonalInfoResponse>;
    landingLogin: (params: ILandingLoginData) => Promise<ILandingLoginResponse>;
}

async function checkAuth() {
    return Promise.resolve(true);
}

async function resetPassword(resetEmail: string) {
    const res: IResetPasswordResponse = {
        status: "success",
        message: "Email reset"
    };
    return res;
}

async function login({loginEmail, loginPassword, staySignedIn}: ILoginData) {
    const res: ILoginResponse = {
        status: "success",
        message: "Login success"
    };
    return res;
}

async function setNewPass(oldPass: string, newPass: string) {
    const res: INewPasswordResponse = {
        status: "success",
        message: "Password changed"
    };
    return res;
}

async function setTestNewPass(oldPass: string, newPass: string) {
    return WaitAction.sec(3).then(() => {
        const res: INewPasswordResponse = {
            status: "success",
            message: "Password changed"
        };
        return res;
    });
}

// Test functions
async function testLogin({loginEmail, loginPassword, staySignedIn}: ILoginData) {
    const isUnkownError = false;
    const isSuccess = true;
    const status = isSuccess ? "success" : "error";
    const message = isSuccess ? "User logined" : "User not found";

    if (isUnkownError) {
        throw new Error("Unknown error on login");
    }

    return WaitAction.sec(3).then(() => {
        const res: ILoginResponse = {
            status,
            message
        };
        return res;
    });
}

async function testCheckAuth() {
    return WaitAction.sec(0.5).then(() => false);
}

async function testResetPassword(resetEmail: string) {
    const isUnkownError = false;
    const isSuccess = true;
    const status = isSuccess ? "success" : "error";
    const message = isSuccess ? "Email reset" : "Email invalid";

    if (isUnkownError) {
        throw new Error("Unknown error on reset password");
    }

    return WaitAction.sec(2).then(() => {
        const res: IResetPasswordResponse = {
            status,
            message
        };
        return res;
    });
}

async function setUserGeneralInfo(params: IUserGeneralInfo) {
    return WaitAction.sec(3).then(() => {
        const res: ISetGeneralUserInfoResponse = {
            status: "success",
            message: "User info updated"
        };
        return res;
    });
}

async function setTestUserGeneralInfo(params: IUserGeneralInfo) {
    return WaitAction.sec(3).then(() => {
        const res: ISetGeneralUserInfoResponse = {
            status: "success",
            message: "User info updated"
        };
        return res;
    });
}

async function changePass(data: IProfilePasswordData) {
    return WaitAction.sec(3).then(() => {
        const res: INewPasswordResponse = {
            status: "success",
            message: "Password changed"
        };
        return res;
    });
}

async function testChangePass(data: IProfilePasswordData) {
    return WaitAction.sec(3).then(() => {
        const res: INewPasswordResponse = {
            status: "success",
            message: "Password changed"
        };
        return res;
    });
}

async function setPersonalInfo(params: IProfilePersonalInfoData) {
    return WaitAction.sec(3).then(() => {
        const res: IProfilePersonalInfoResponse = {
            status: "success",
            message: "User info updated"
        };
        return res;
    });
}

async function testSetPersonalInfo(params: IProfilePersonalInfoData) {
    return WaitAction.sec(3).then(() => {
        const res: IProfilePersonalInfoResponse = {
            status: "success",
            message: "User info updated"
        };
        return res;
    });
}

export function setPersonalID(params: IProfilePersonalID) {
    return WaitAction.sec(3).then(() => {
        const res: IProfilePersonalInfoResponse = {
            status: "success",
            message: "User persoanl ID saved"
        };
        return res;
    });
}

export function testSetPersonalID(params: IProfilePersonalID) {
    console.log("testSetPersonalID", params);
    return WaitAction.sec(3).then(() => {
        const res: IProfilePersonalInfoResponse = {
            status: "success",
            message: "User persoanal ID saved"
        };
        return res;
    });
}

export async function landingLogin(params: ILandingLoginData) {
    console.log("landingLogin", params);

    if (!params.phone) {
        params.phone = "---";
    }

    const res: ILandingLoginResponse = {
        status: "success",
        message: "Login success"
    };

    let serverError: string | string[] | undefined = undefined;

    try {
        const response = await fetch("https://jetsaver.uk/v1/register", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(params)
        });

        if (!response.ok) {
            const errorData = await response.json();
            if (errorData && errorData.errors && errorData.errors.length > 0) {
                serverError = errorData.errors || "Unknown server error";
            }
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        res.status = "success";
        res.message = "Login success";
    } catch (error) {
        console.error("Error:", error);
        res.status = "error";
        if (serverError) {
            res.message = serverError;
        } else {
            res.message = `${error}`;
        }
    }

    return res;
}

export const API: IServerAPI = {
    checkAuth: IS_DEV_FRONTEND_MODE ? testCheckAuth : checkAuth,
    resetPassword: IS_DEV_FRONTEND_MODE ? testResetPassword : resetPassword,
    login: IS_DEV_FRONTEND_MODE ? testLogin : login,
    setNewPass: IS_DEV_FRONTEND_MODE ? setTestNewPass : setNewPass,
    setUserGeneralInfo: IS_DEV_FRONTEND_MODE ? setTestUserGeneralInfo : setUserGeneralInfo,
    changePass: IS_DEV_FRONTEND_MODE ? testChangePass : changePass,
    setPersonalInfo: IS_DEV_FRONTEND_MODE ? testSetPersonalInfo : setPersonalInfo,
    setPersonalID: IS_DEV_FRONTEND_MODE ? testSetPersonalID : setPersonalID,
    landingLogin: IS_DEV_FRONTEND_MODE ? landingLogin : landingLogin
};
