import {observer} from "mobx-react";
import {useForm} from "react-hook-form";
import useFormSubmit, {ApiCall} from "../../customHooks/useFormSubmit";
import {useSafeTranslations} from "../../customHooks/useSafeTranslations";
import {API} from "../../services/ApiService";
import {IProfilePersonalInfoData, IProfilePersonalInfoResponse} from "../../services/interfaces";
import {Header4} from "../common";
import {SecondaryButton} from "../common/Buttons";
import {CheckboxControlled, Field, TextField} from "../fieldComponents";
import {BirthField, FirstNameField, LastNameField, PhoneField} from "../fields";
import {AccountFormFooter, FormContent, FormHeader} from "../formComponents";
import {useDialogContext} from "../../customHooks/useDialogContext";
import {Button} from "primereact/button";
import RegisterFormPersonalID from "./RegisterFormPersonalID";
import {RegisterFormPersonalIDDialog} from "../dialogs";
import {useEffect, useRef} from "react";

export const ProfilePersonalInfoForm = observer(() => {
    const {translate} = useSafeTranslations();
    const {openDialog, closeDialog} = useDialogContext();

    const defaultValues: IProfilePersonalInfoData = {
        allowEmailNotifications: true,
        allowSMSNotifications: true,
        allowPushNotifications: true,
        firstName: "",
        lastName: "",
        birthDay: "",
        phone: ""
    };

    const {
        control,
        handleSubmit,
        setError,
        reset,
        getValues,
        formState: {errors, isSubmitting}
    } = useForm<IProfilePersonalInfoData>({defaultValues});

    const apiCall: ApiCall<IProfilePersonalInfoData, IProfilePersonalInfoResponse> = data =>
        API.setPersonalInfo(data);

    const {onSubmit, isSuccessfullySubmitted} = useFormSubmit<
        IProfilePersonalInfoData,
        IProfilePersonalInfoResponse
    >({
        apiCall,
        fields: [
            "phone",
            "firstName",
            "lastName",
            "birthDay",
            "allowEmailNotifications",
            "allowSMSNotifications",
            "allowPushNotifications"
        ],
        setError,
        onSuccess: data => {
            console.log("onSuccess", data);
            reset();
        }
    });

    const onPathVerificationClick = () => {
        openDialog(RegisterFormPersonalIDDialog, {
            onCloseDialog: closeDialog
        });
    };

    return (
        <form
            className="form form--wide form--tab-adapted form--profile-personal-info"
            onSubmit={handleSubmit(onSubmit)}
        >
            <FormHeader></FormHeader>
            <FormContent>
                <div className="grid">
                    <div className="col-6 p-5 pb-0 pt-0">
                        <Header4>{translate("general-information-text")}</Header4>
                        <FirstNameField
                            control={control}
                            errors={errors.firstName}
                            disabled={isSubmitting}
                        />
                        <LastNameField
                            control={control}
                            errors={errors.lastName}
                            disabled={isSubmitting}
                        />

                        <PhoneField
                            control={control}
                            errors={errors.phone}
                            disabled={isSubmitting}
                        />

                        <BirthField
                            control={control}
                            errors={errors.birthDay}
                            disabled={isSubmitting}
                        />
                        {/*  */}
                        <Header4>{translate("notifacation-text")}</Header4>
                        <CheckboxControlled
                            name="allowSMSNotifications"
                            control={control}
                            disabled={isSubmitting}
                            label={
                                <label
                                    className="checkbox-label ml-2"
                                    htmlFor="allowSMSNotifications"
                                >
                                    {"I allow notification via SMS"}
                                </label>
                            }
                        />
                        <CheckboxControlled
                            name="allowEmailNotifications"
                            control={control}
                            disabled={isSubmitting}
                            label={
                                <label
                                    className="checkbox-label ml-2"
                                    htmlFor="allowEmailNotifications"
                                >
                                    {"I allow notification via email"}
                                </label>
                            }
                        />
                        <CheckboxControlled
                            name="allowPushNotifications"
                            control={control}
                            disabled={isSubmitting}
                            label={
                                <label
                                    className="checkbox-label ml-2"
                                    htmlFor="allowPushNotifications"
                                >
                                    {"I allow browser notification"}
                                </label>
                            }
                        />
                    </div>
                    <div className="col-6 p-5 pb-0 pt-0">
                        <Header4>{translate("personal-id-verifiaction")}</Header4>
                        <Field>
                            <SecondaryButton
                                type={"button"}
                                className={"secondary-button--w-full"}
                                onClick={onPathVerificationClick}
                                label={translate("pass-verification-button")}
                                disabled={isSubmitting}
                            />
                        </Field>

                        <TextField id={"id-number"} disabled={true} value={"MR54654564"} />
                    </div>
                </div>
            </FormContent>
            <AccountFormFooter
                disabled={isSubmitting}
                onCancelClick={() => {
                    console.log("cancel");
                }}
                onSaveClick={() => {
                    console.log("save");
                }}
            />
        </form>
    );
});
