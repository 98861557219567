import {observer} from "mobx-react";
import PageContent from "../../components/MainPageBlocks/PageContent";
import RegisterFormPersonalID from "../../components/forms/RegisterFormPersonalID";
import {LoginForm} from "../../components/forms/LoginForm";
import {NewPasswordForm} from "../../components/forms/NewPasswordForm";
import RegisterFormPersonalIDUpload from "../../components/forms/RegisterFormPersonalIDUpload";
import {ProfilePersonalInfoForm} from "../../components/forms/ProfilePersonalInfoForm";
import {AccountTabs} from "../../components/tabs/AccountTabs";
import {ResetPasswordForm} from "../../components/forms/ResetPasswordForm";
import RegisterFormGeneralInfo from "../../components/forms/RegisterFormGeneralInfo";
import {PagesId} from "../../constants/PAGES_ID";
import {LandingLoginForm} from "../../components/forms/LandingLoginForm";

const pageId = PagesId.Login;

const LoginPage = observer(() => {
    return (
        <PageContent className={"page-login"} pageId={pageId}>
            <LandingLoginForm />
            {/* <LoginForm />
                <NewPasswordForm />
                <ResetPasswordForm />
                <RegisterFormGeneralInfo />
                <RegisterFormPersonalIDUpload />
                <AccountTabs /> */}
        </PageContent>
    );
});

export default LoginPage;
