import {AircraftData} from "../services/interfaces";
import {Random} from "../utils/Random";

const IMAGES = [
    "../images/aircraft_80_80_default.png",
    "../images/aircraft_80_80_default_1.png",
    "../images/aircraft_80_80_default_2.png",
    "../images/aircraft_80_80_default_3.png"
];

export const AIRCRAFTS_DATA: AircraftData[] = [
    {
        name: "Gulfstream G650ER",
        description:
            "One of the longest-range and fastest business jets, offering exceptional performance and luxury."
    },
    {
        name: "Bombardier Global 7500",
        description:
            "Features a spacious cabin with advanced avionics and impressive range for long-haul flights."
    },
    {
        name: "Bombardier Global 5500",
        description:
            "An enhanced version with increased range and comfort, ideal for intercontinental travel."
    },
    {
        name: "Dassault Falcon 8X",
        description:
            "A trijet offering exceptional reliability, range, and flexibility with advanced technology."
    },
    {
        name: "Gulfstream G500",
        description:
            "Known for its speed and range, providing a luxurious and efficient travel experience."
    },
    {
        name: "Bombardier Challenger 350",
        description:
            "A popular choice in the mid-size segment, offering reliability and comfort for medium-range flights."
    },
    {
        name: "Cessna Citation Longitude",
        description:
            "Combines modern technology with comfort, suitable for both business and private travel."
    },
    {
        name: "Embraer Praetor 600",
        description:
            "Offers an excellent balance of range and passenger capacity with advanced cabin features."
    },
    {
        name: "Dassault Falcon 7X",
        description:
            "A trijet known for its high performance and versatility in various flight conditions."
    },
    {
        name: "Gulfstream G600",
        description:
            "Enhanced control capabilities and improved performance for a superior flying experience."
    },
    {
        name: "Bombardier Challenger 3500",
        description:
            "A modified version of the Challenger 350, offering upgraded features and enhanced performance."
    },
    {
        name: "Embraer Legacy 650E",
        description:
            "An advanced version of the Legacy 650, providing extended range and enhanced cabin amenities."
    },
    {
        name: "Dassault Falcon 900LX",
        description:
            "A trijet offering exceptional range and comfort with advanced avionics and luxurious interiors."
    },
    {
        name: "Cessna Citation Latitude",
        description:
            "Widely used for both commercial and private flights, offering reliability and efficiency."
    },
    {
        name: "Learjet 75",
        description:
            "A light and fast mid-size jet, perfect for quick trips and short to medium-range flights."
    },
    {
        name: "Hawker 4000",
        description:
            "Features a modern design with advanced technologies, providing comfort and efficiency."
    },
    {
        name: "Gulfstream G280",
        description:
            "Combines impressive range with efficiency, making it a versatile choice for various travel needs."
    },
    {
        name: "Dassault Falcon 2000LXS",
        description: "Offers high performance and comfort, suitable for long-range business travel."
    },
    {
        name: "Embraer Phenom 300E",
        description:
            "One of the most popular light business jets, known for its reliability and advanced features."
    },
    {
        name: "Cessna Citation Sovereign+",
        description:
            "An enhanced version offering greater comfort and improved performance for longer flights."
    }
    // Добавьте остальные модели при необходимости
].map((item: AircraftData) => {
    const image = Random.from(IMAGES);
    return {...item, image};
});

export const DEFAULT_AIRCRAFT_DATA: AircraftData = AIRCRAFTS_DATA[0];
