import {observer} from "mobx-react";
import {FloatLabel} from "primereact/floatlabel";
import {useForm} from "react-hook-form";
import {
    AIRPORT_FROM_VALIDATION_RULE,
    AIRPORT_TO_VALIDATION_RULE,
    PASSENGERS_TOTAL_VALIDATION_RULE
} from "../../constants";
import {AIRPORTS_DATA, DEFAULT_AIRPORT_FROM} from "../../constants/AIRPORTS";
import useFormSubmit, {ApiCall} from "../../customHooks/useFormSubmit";
import {useSafeTranslations} from "../../customHooks/useSafeTranslations";
import {
    AirportData,
    ISearchResult,
    ITicketSearchData,
    TicketSearchResult
} from "../../services/interfaces";
import {getTestTicket} from "../../services/TestTicketService";
import {useRootStore} from "../../store/RootStore";
import {MainButton} from "../common/Buttons";
import {
    AutocompleteAirportFieldControlled,
    CalendarControlled,
    DropdownFieldControlled,
    Field,
    TextFieldControlled
} from "../fieldComponents";

type TicketSearchFormProps = {
    onSuccessSearch?: (data: TicketSearchResult[]) => void;
};

export const TicketsSearchForm = observer(({onSuccessSearch}: TicketSearchFormProps) => {
    const {userStore, searchTicketsStore} = useRootStore();
    const {isLoggedIn} = userStore;
    const {translate} = useSafeTranslations();

    const defaultValues: ITicketSearchData = {
        airportFrom: searchTicketsStore.ticketsSearchParams.airportFrom,
        airportTo: searchTicketsStore.ticketsSearchParams.airportTo,
        passengers: searchTicketsStore.ticketsSearchParams.passengers,
        dates: searchTicketsStore.ticketsSearchParams.dates
    };

    const {
        control,
        handleSubmit,
        setError,
        formState: {errors, isSubmitting},
        reset,
        watch,
        getValues
    } = useForm<ITicketSearchData>({
        defaultValues
    });
    //

    const apiCall: ApiCall<ITicketSearchData, ISearchResult> = data => getTestTicket(data);

    const {onSubmit, isSuccessfullySubmitted} = useFormSubmit<ITicketSearchData, ISearchResult>({
        apiCall,
        fields: ["airportFrom", "airportTo", "dates", "passengers"],
        setError,
        onError: e => {
            console.error("onError", e);
        },
        onSuccess: searchResultData => {
            const values = getValues();
            //
            searchTicketsStore.setTicketsSearchParams(values);
            //
            console.log("onSuccess", searchResultData);
            onSuccessSearch?.(searchResultData.data);
        }
    });

    const passengers = [
        {name: "1 Passenger", code: 1},
        {name: "2 Passengers", code: 2},
        {name: "3 Passengers", code: 3},
        {name: "4 Passengers", code: 4},
        {name: "5 Passengers", code: 5}
    ];

    const createNewAirport = (input: string): AirportData => {
        return {
            shortName: input, // Оставляем регистр, как ввел пользователь
            name: `Custom Airport for ${input}`,
            city_code: "N/A",
            country_code: "N/A",
            time_zone: "N/A",
            code: input, // Оставляем исходный регистр
            coordinates: {lat: 0, lon: 0}
        };
    };

    return (
        <form className={"form form--w-full form--search-ticket"} onSubmit={handleSubmit(onSubmit)}>
            <div className={"col-12 form-col "}>
                <FloatLabel>
                    {/* <DropdownFieldControlled
                        name={"airportFrom"}
                        placeholder={translate("from-text")}
                        disabled={isSubmitting}
                        control={control}
                        options={AIRPORTS_DATA}
                        optionName="shortName"
                        errors={errors.airportFrom}
                    /> */}
                    <AutocompleteAirportFieldControlled
                        name={"airportFrom"}
                        placeholder={translate("from-text")}
                        disabled={isSubmitting}
                        control={control}
                        suggestions={AIRPORTS_DATA}
                        fieldName="shortName"
                        createNew={createNewAirport}
                        errors={errors.airportFrom}
                        // rulles={{...AIRPORT_FROM_VALIDATION_RULE}}
                    />
                    <label htmlFor="airportFrom">{translate("from-text")}</label>
                </FloatLabel>
            </div>
            <div className={"col-12 form-col "}>
                <FloatLabel>
                    <AutocompleteAirportFieldControlled
                        name={"airportTo"}
                        placeholder={translate("from-text")}
                        disabled={isSubmitting}
                        control={control}
                        suggestions={AIRPORTS_DATA}
                        fieldName="shortName"
                        createNew={createNewAirport}
                        errors={errors.airportTo}
                        // rulles={{...AIRPORT_TO_VALIDATION_RULE}}
                    />
                    {/* <DropdownFieldControlled
                        name={"airportTo"}
                        placeholder={translate("to-text")}
                        disabled={isSubmitting}
                        control={control}
                        options={AIRPORTS_DATA}
                        optionName="shortName"
                        errors={errors.airportTo}
                    /> */}
                    <label htmlFor="airportTo">{translate("to-text")}</label>
                </FloatLabel>
            </div>
            <div className={"col-12 form-col "}>
                <FloatLabel>
                    <CalendarControlled
                        minDate={new Date()}
                        name={"dates"}
                        control={control}
                        disabled={isSubmitting}
                        placeholder={translate("dates-text")}
                        selectionMode="range"
                        numberOfMonths={2}
                        readOnlyInput={true}
                        showIcon={true}
                        hideOnRangeSelection={true}
                        showButtonBar={true}
                    />
                    <label htmlFor="dates">{translate("dates-text")}</label>
                </FloatLabel>
            </div>
            <div className={"col-12 form-col "}>
                <FloatLabel>
                    <TextFieldControlled
                        name={"passengers"}
                        placeholder={translate("passengers-text")}
                        control={control}
                        keyfilter={"pint"}
                        disabled={isSubmitting}
                        errors={errors.passengers}
                        rulles={{...PASSENGERS_TOTAL_VALIDATION_RULE}}
                    />
                    {/* <DropdownFieldControlled
                        name={"passengers"}
                        placeholder={translate("passengers-text")}
                        disabled={isSubmitting}
                        control={control}
                        options={passengers}
                        optionName="name"
                        rulles={{...PASSENGERS_TOTAL_VALIDATION_RULE}}
                        errors={errors.passengers}
                    /> */}
                    <label htmlFor="passengers">{translate("passengers-text")}</label>
                </FloatLabel>
            </div>
            <div className={"col-12 form-col form-col--search-btn"}>
                <Field>
                    <MainButton
                        className={"main-button--w-full justify-content-center"}
                        type={"submit"}
                        label={translate("search-btn-text")}
                    />
                </Field>
            </div>
        </form>
    );
});
