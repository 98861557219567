import {Card} from "primereact/card";
import {MainButton} from "../common/Buttons";
import {
    TicketCardChips,
    TicketCardFlightDetails,
    TicketCardFlightRouteDetails,
    TicketCardPrice
} from "./CardComponents";
import {TicketCardLikeButton} from "./TicketCardLikeButton";
import {observer} from "mobx-react";
import {TicketData} from "../../services/interfaces";
import {useNavigate} from "react-router-dom";

type TicketCardProps = {
    className?: string;
    key: string;
    data: TicketData;
};

export const TicketCard = observer(({className, data, key}: TicketCardProps) => {
    const navigate = useNavigate();

    const mainButtonText = "Select Ticket";

    const onClickHandler = () => {
        navigate("/login");
    };

    return (
        <Card key={key} className={"ticket-card"}>
            <div className={"grid grid-nogutter justify-content-between align-items-center w-full"}>
                <TicketCardFlightDetails
                    className={"col-10 sm:col-8 md:col-4 lg:col-3 xl:col-3 flex-order-0"}
                    chips={<TicketCardChips chipData={data.chips} />}
                    aircraftData={data.aircraftData}
                />

                <TicketCardFlightRouteDetails
                    flightRouteData={data.flightRouteData}
                    className={
                        "col-12 md:col-6 lg:col-5 xl:col-4 flex-order-2 md:flex-order-1 gap-1"
                    }
                />

                <TicketCardPrice
                    price={data.price}
                    className={"flex-order-3 lg:col-2 xl:col-2 lg:flex-order-2"}
                />

                <div
                    className={
                        "/*col-2 md:col-1*/ flex justify-content-center align-items-center flex-order-1 md:flex-order-2 lg:flex-order-3"
                    }
                >
                    <TicketCardLikeButton />
                </div>
                <div className={"flex-order-4"}>
                    <MainButton type={"button"} onClick={onClickHandler} label={mainButtonText} />
                </div>
            </div>
        </Card>
    );
});

// export const TicketCard = () => {
//     const mainButtonText = "Select ticket";

//     const onClickHandler = () => {
//         console.log("on click handler");
//     };

//     return (
//         <Card className={"ticket-card"}>
//             <div className={"grid grid-nogutter justify-content-between align-items-center w-full"}>
//                 <TicketCardFlightDetails
//                     className={"col-10 sm:col-8 md:col-4 lg:col-3 xl:col-3 flex-order-0"}
//                     chips={<TicketCardChips />}
//                 />

//                 <TicketCardFlightRouteDetails
//                     className={
//                         "col-12 md:col-6 lg:col-5 xl:col-4 flex-order-2 md:flex-order-1 gap-1"
//                     }
//                 />

//                 <TicketCardPrice className={"flex-order-3 lg:col-2 xl:col-2 lg:flex-order-2"} />

//                 <div
//                     className={
//                         "/*col-2 md:col-1*/ flex justify-content-center align-items-center flex-order-1 md:flex-order-2 lg:flex-order-3"
//                     }
//                 >
//                     <TicketCardLikeButton />
//                 </div>
//                 <div className={"flex-order-4"}>
//                     <MainButton type={"button"} onClick={onClickHandler} label={mainButtonText} />
//                 </div>
//             </div>
//         </Card>
//     );
// };
