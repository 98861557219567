import React, {useEffect, useRef} from "react";
import {useForm} from "react-hook-form";
import {ID_NUMBER_VALIDATION_RULE} from "../../constants";
import useFormSubmit, {ApiCall} from "../../customHooks/useFormSubmit";
import {useSafeTranslations} from "../../customHooks/useSafeTranslations";
import {API} from "../../services/ApiService";
import {IProfilePersonalID, IProfilePersonalIDResponse} from "../../services/interfaces";
import {MainButton, SecondaryButton} from "../common/Buttons";
import {TextFieldControlled} from "../fieldComponents";
import {
    ContentB1Form,
    FormContent,
    FormFooter,
    FormHeader,
    FormProvideLaterLink,
    Header2Form
} from "../formComponents";
import {IDialogFormProps} from "./interfaces";
import {classNames} from "primereact/utils";

const RegisterFormPersonalID: React.FC<IDialogFormProps> = ({
    onCancel,
    onSuccess,
    className,
    CloseComponent = FormProvideLaterLink
}) => {
    const defaultValues = {
        idNumber: ""
    };

    const {translate} = useSafeTranslations();
    const {
        control,
        handleSubmit,
        setError,
        formState: {errors, isSubmitting},
        reset
    } = useForm<IProfilePersonalID>({
        defaultValues
    });

    const apiCall: ApiCall<IProfilePersonalID, IProfilePersonalIDResponse> = data =>
        API.setPersonalID(data);

    const {onSubmit, isSuccessfullySubmitted} = useFormSubmit<
        IProfilePersonalID,
        IProfilePersonalIDResponse
    >({
        apiCall,
        fields: ["idNumber"],
        setError,
        onSuccess: data => {
            console.log("onSuccess", data);
            onSuccess && onSuccess();
            reset();
        }
    });

    const onClickCancel = () => onCancel && onCancel();

    return (
        <>
            <form
                className={classNames("form form--register-personal-id", className)}
                onSubmit={handleSubmit(onSubmit)}
            >
                <FormHeader>
                    {CloseComponent && <CloseComponent disabled={isSubmitting} />}
                    <Header2Form>{translate("register-form-header-text")}</Header2Form>
                    <ContentB1Form>{translate("personal-id-information")}</ContentB1Form>
                </FormHeader>
                <FormContent className={"mt-5"}>
                    <TextFieldControlled
                        placeholder={translate("id-number-text")}
                        control={control}
                        name={"idNumber"}
                        errors={errors.idNumber}
                        disabled={isSubmitting}
                        rulles={{...ID_NUMBER_VALIDATION_RULE}}
                    />
                </FormContent>
                <FormFooter className={"form__footer--row-directed justify-content-between"}>
                    <SecondaryButton
                        type={"button"}
                        className={"secondary-button--w-full"}
                        label={translate("back-button-text")}
                        disabled={isSubmitting}
                        onClick={onClickCancel}
                    />
                    <MainButton
                        className={"main-button--w-full"}
                        type="submit"
                        label={translate("confirm-button-text")}
                        disabled={isSubmitting}
                    />
                </FormFooter>
            </form>
        </>
    );
};

export default RegisterFormPersonalID;
