import {Button} from "primereact/button";
import {ReactComponent as HeartUnfilledIcon} from "../../icons/icon-heart-unfilled.svg";
import {ReactComponent as HeartFilledIcon} from "../../icons/icon-heart-filled.svg";

type TicketCardLikeButtonProps = {
    className?: string;
    initialLike?: boolean;
    id?: string;
};

export const TicketCardLikeButton = ({
    className,
    initialLike = false,
    id
}: TicketCardLikeButtonProps) => {
    const toggleLike = () => {
        console.error("toggleLike");
    };

    return (
        <HeartUnfilledIcon />
        // <Button
        //     className={"like-button"}
        //     rounded
        //     text
        //     type="button"
        //     icon={<HeartUnfilledIcon />}
        //     onClick={toggleLike}
        //     aria-label="Like"
        // />
    );
};
