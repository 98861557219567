import React, {ReactNode} from "react";
import {DefaultPageTitle} from "../../constants";
import useDocumentTitle from "../../customHooks/useDocumentTitle";
import {getPageMetaById} from "../../helpers";
import {PagesId} from "../../constants/PAGES_ID";
import {classNames} from "primereact/utils";

interface PageContentProps {
    pageId?: PagesId;
    children?: ReactNode;
    className?: string;
}

const PageContent: React.FC<PageContentProps> = ({children, className, pageId}) => {
    useDocumentTitle(pageId ? getPageMetaById(pageId).title : DefaultPageTitle);
    return (
        <div
            className={classNames(
                "page",
                className,
                "w-full h-full grid grid-nogutter justify-content-center"
            )}
        >
            {children ? children : null}
        </div>
    );
};

export default PageContent;
