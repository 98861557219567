import React, {ReactNode} from "react";

interface PageWrapperProps {
    children?: ReactNode;
}

const PageWrapper: React.FC<PageWrapperProps> = ({children}) => {
    return <div className="w-full h-screen flex flex-column">{children ? children : null}</div>;
};

export default PageWrapper;
