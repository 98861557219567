import {AirportData} from "../services/interfaces";

export const AIRPORTS_DATA: AirportData[] = [
    {
        shortName: "Atlanta",
        name: "Hartsfield-Jackson Atlanta International Airport",
        city_code: "ATL",
        country_code: "US",
        time_zone: "America/New_York",
        code: "ATL",
        coordinates: {
            lat: 33.6407,
            lon: -84.4277
        }
    },
    {
        shortName: "Beijing",
        name: "Beijing Capital International Airport",
        city_code: "PEK",
        country_code: "CN",
        time_zone: "Asia/Shanghai",
        code: "PEK",
        coordinates: {
            lat: 40.0801,
            lon: 116.5846
        }
    },
    {
        shortName: "Dubai",
        name: "Dubai International Airport",
        city_code: "DXB",
        country_code: "AE",
        time_zone: "Asia/Dubai",
        code: "DXB",
        coordinates: {
            lat: 25.2532,
            lon: 55.3657
        }
    },
    {
        shortName: "Los Angeles",
        name: "Los Angeles International Airport",
        city_code: "LAX",
        country_code: "US",
        time_zone: "America/Los_Angeles",
        code: "LAX",
        coordinates: {
            lat: 33.9416,
            lon: -118.4085
        }
    },
    {
        shortName: "Tokyo",
        name: "Tokyo Haneda Airport",
        city_code: "HND",
        country_code: "JP",
        time_zone: "Asia/Tokyo",
        code: "HND",
        coordinates: {
            lat: 35.5494,
            lon: 139.7798
        }
    },
    {
        shortName: "O'Hare",
        name: "O'Hare International Airport",
        city_code: "ORD",
        country_code: "US",
        time_zone: "America/Chicago",
        code: "ORD",
        coordinates: {
            lat: 41.9742,
            lon: -87.9073
        }
    },
    {
        shortName: "London",
        name: "London Heathrow Airport",
        city_code: "LHR",
        country_code: "GB",
        time_zone: "Europe/London",
        code: "LHR",
        coordinates: {
            lat: 51.47,
            lon: -0.4543
        }
    },
    {
        shortName: "Shanghai",
        name: "Shanghai Pudong International Airport",
        city_code: "PVG",
        country_code: "CN",
        time_zone: "Asia/Shanghai",
        code: "PVG",
        coordinates: {
            lat: 31.1443,
            lon: 121.8083
        }
    },
    {
        shortName: "Paris",
        name: "Paris Charles de Gaulle Airport",
        city_code: "CDG",
        country_code: "FR",
        time_zone: "Europe/Paris",
        code: "CDG",
        coordinates: {
            lat: 49.0097,
            lon: 2.5479
        }
    },
    {
        shortName: "Dallas",
        name: "Dallas/Fort Worth International Airport",
        city_code: "DFW",
        country_code: "US",
        time_zone: "America/Chicago",
        code: "DFW",
        coordinates: {
            lat: 32.8998,
            lon: -97.0403
        }
    },
    {
        shortName: "Frankfurt",
        name: "Frankfurt Airport",
        city_code: "FRA",
        country_code: "DE",
        time_zone: "Europe/Berlin",
        code: "FRA",
        coordinates: {
            lat: 50.0379,
            lon: 8.5622
        }
    },
    {
        shortName: "Singapore",
        name: "Singapore Changi Airport",
        city_code: "SIN",
        country_code: "SG",
        time_zone: "Asia/Singapore",
        code: "SIN",
        coordinates: {
            lat: 1.3644,
            lon: 103.9915
        }
    },
    {
        shortName: "Seoul",
        name: "Incheon International Airport",
        city_code: "ICN",
        country_code: "KR",
        time_zone: "Asia/Seoul",
        code: "ICN",
        coordinates: {
            lat: 37.4602,
            lon: 126.4407
        }
    },
    {
        shortName: "Hong Kong",
        name: "Hong Kong International Airport",
        city_code: "HKG",
        country_code: "HK",
        time_zone: "Asia/Hong_Kong",
        code: "HKG",
        coordinates: {
            lat: 22.308,
            lon: 113.9185
        }
    },
    {
        shortName: "Amsterdam",
        name: "Amsterdam Schiphol Airport",
        city_code: "AMS",
        country_code: "NL",
        time_zone: "Europe/Amsterdam",
        code: "AMS",
        coordinates: {
            lat: 52.3105,
            lon: 4.7683
        }
    },
    {
        shortName: "Denver",
        name: "Denver International Airport",
        city_code: "DEN",
        country_code: "US",
        time_zone: "America/Denver",
        code: "DEN",
        coordinates: {
            lat: 39.8561,
            lon: -104.6737
        }
    },
    {
        shortName: "Bangkok",
        name: "Suvarnabhumi Airport",
        city_code: "BKK",
        country_code: "TH",
        time_zone: "Asia/Bangkok",
        code: "BKK",
        coordinates: {
            lat: 13.69,
            lon: 100.7501
        }
    },
    {
        shortName: "Madrid",
        name: "Madrid-Barajas Airport",
        city_code: "MAD",
        country_code: "ES",
        time_zone: "Europe/Madrid",
        code: "MAD",
        coordinates: {
            lat: 40.4901,
            lon: -3.5878
        }
    },
    {
        shortName: "Barcelona",
        name: "Barcelona–El Prat Airport",
        city_code: "BCN",
        country_code: "ES",
        time_zone: "Europe/Madrid",
        code: "BCN",
        coordinates: {
            lat: 41.2974,
            lon: 2.0833
        }
    },
    {
        shortName: "Istanbul",
        name: "Istanbul Airport",
        city_code: "IST",
        country_code: "TR",
        time_zone: "Europe/Istanbul",
        code: "IST",
        coordinates: {
            lat: 41.2753,
            lon: 28.7519
        }
    },
    {
        shortName: "Frankfurt-Hahn",
        name: "Frankfurt Hahn Airport",
        city_code: "HHN",
        country_code: "DE",
        time_zone: "Europe/Berlin",
        code: "HHN",
        coordinates: {
            lat: 49.9481,
            lon: 7.2632
        }
    },
    {
        shortName: "Miami",
        name: "Miami International Airport",
        city_code: "MIA",
        country_code: "US",
        time_zone: "America/New_York",
        code: "MIA",
        coordinates: {
            lat: 25.7959,
            lon: -80.287
        }
    },
    {
        shortName: "Phoenix",
        name: "Phoenix Sky Harbor International Airport",
        city_code: "PHX",
        country_code: "US",
        time_zone: "America/Phoenix",
        code: "PHX",
        coordinates: {
            lat: 33.4342,
            lon: -112.0116
        }
    }
];

export const DEFAULT_AIRPORT_FROM: AirportData = AIRPORTS_DATA[0];
export const DEFAULT_AIRPORT_TO: AirportData = AIRPORTS_DATA[1];

function getFirstWord(str: string): string {
    str = str.trim();
    if (str === "") {
        return "";
    }
    return str.split(" ")[0];
}
