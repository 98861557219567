import {observer} from "mobx-react";
import {Button} from "primereact/button";
import {useState} from "react";
import {useForm} from "react-hook-form";
import {EMAIL_VALIDATION_RULES} from "../../constants";
import {ReactComponent as FormHeaderIconEmail} from "../../icons/icon-form-header-email.svg";
import {ReactComponent as FormHeaderIconLock} from "../../icons/icon-form-header-lock.svg";
import {API} from "../../services/ApiService";
import {Field, TextField} from "../fieldComponents/FieldComponents";
import {useSafeTranslations} from "../../customHooks/useSafeTranslations";
import {FormContent, FormFooter, FormHeader, FormHeaderIcon} from "../formComponents";
import {Header3} from "../common";
// import {FormHeaderIcon} from "./components/FormComponents";

interface IResetPasswordForm {
    resetEmail: string;
}

export const ResetPasswordForm = observer(() => {
    const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);
    const {translate} = useSafeTranslations();

    const {
        register,
        handleSubmit,
        setError,
        reset,
        getValues,
        formState: {errors, isSubmitting}
    } = useForm<IResetPasswordForm>();

    const onSubmit = async (data: IResetPasswordForm) => {
        console.log(data);
        try {
            const res = await API.resetPassword(data.resetEmail);
            if (res.status === "success") {
                setIsSuccessfullySubmitted(true);
            }
        } catch (e) {
            // Server error hadling
            console.error(e);
            // if (errors.username) {
            //   setError('username', {
            //     type: "server",
            //     message: 'Something went wrong with username',
            //   });
            // }
        }
    };
    //

    // TODO move to text i18n all texts

    const ResetPasswordSuccessMessageText = `We will sent you email to ${getValues(
        "resetEmail"
    )} Check your email and follow instructions`;

    //

    const handleWrongEmailClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        reset();
        setIsSuccessfullySubmitted(false);
    };

    return (
        <form className="form form--reset-password" onSubmit={handleSubmit(onSubmit)}>
            <FormHeader className={"align-items-center"}>
                <FormHeaderIcon
                    className={"reset-password-form__form-header-icon"}
                    icon={
                        isSuccessfullySubmitted ? <FormHeaderIconEmail /> : <FormHeaderIconLock />
                    }
                />
                <Header3>{translate("reset-password-form-header-text")}</Header3>

                <Field>
                    <div className="content-b3 form-description">
                        {isSuccessfullySubmitted
                            ? ResetPasswordSuccessMessageText
                            : translate("reset-password-form-message-text")}
                    </div>
                </Field>
            </FormHeader>
            <FormContent className={"pt-3"}>
                {isSuccessfullySubmitted ? null : (
                    <TextField
                        id={"resetEmail"}
                        register={register}
                        rulles={{
                            ...EMAIL_VALIDATION_RULES
                        }}
                        placeholder={translate("email-text")}
                        errors={errors.resetEmail}
                        disabled={isSubmitting}
                    />
                )}
            </FormContent>
            <FormFooter>
                {isSuccessfullySubmitted ? (
                    <div className={"p-informer p-informer--align-center"}>
                        <a href="#" onClick={handleWrongEmailClick} className={"p-informer__link"}>
                            {translate("wrong-email-link-text")}
                        </a>
                    </div>
                ) : (
                    <Field>
                        <Button
                            name={"submit"}
                            type={"submit"}
                            className="main-button main-button--w-full justify-content-center"
                            label={translate("send-button-text")}
                            disabled={isSubmitting}
                        />
                    </Field>
                )}
            </FormFooter>
        </form>
    );
});
