import {ReactNode} from "react";
import PageWrapper from "../../components/MainPageBlocks/Page";
import {useDialogContext} from "../../customHooks/useDialogContext";
import {CloseFormIconButton} from "../formComponents";
import RegisterFormPersonalID from "../forms/RegisterFormPersonalID";
import RegisterFormPersonalIDUpload from "../forms/RegisterFormPersonalIDUpload";
import {useToast} from "../../customHooks/useToast";
import {LandingLoginForm} from "../forms/LandingLoginForm";
import {useNavigate} from "react-router-dom";

type DialogWrapperProps = {
    children?: ReactNode;
};

type FormDialogProps = {
    onCloseDialog: () => void;
};

export const DialogWrapper = ({children}: DialogWrapperProps) => {
    return <PageWrapper>{children ? children : null}</PageWrapper>;
};

export const RegisterFormPersonalIDUploadDialog = ({onCloseDialog}: FormDialogProps) => {
    return (
        <DialogWrapper>
            <RegisterFormPersonalIDUpload
                CloseComponent={() => (
                    <CloseFormIconButton
                        onClick={() => {
                            onCloseDialog();
                        }}
                    />
                )}
                className={"form--in-dialog"}
                onCancel={onCloseDialog}
                onSuccess={() => {
                    onCloseDialog();
                }}
            />
        </DialogWrapper>
    );
};

export const RegisterFormPersonalIDDialog = ({onCloseDialog}: FormDialogProps) => {
    const {openDialog, closeDialog} = useDialogContext();
    const showToast = useToast();

    return (
        <DialogWrapper>
            <RegisterFormPersonalID
                CloseComponent={() => (
                    <CloseFormIconButton
                        onClick={() => {
                            if (showToast) {
                                showToast("success", "FFF", "dsvfsd", "top-left", 100000);
                            }
                            onCloseDialog();
                        }}
                    />
                )}
                className={"form--in-dialog"}
                onCancel={onCloseDialog}
                onSuccess={() => {
                    onCloseDialog();
                    openDialog(RegisterFormPersonalIDUploadDialog, {onCloseDialog: closeDialog});
                }}
            />
        </DialogWrapper>
    );
};

export const LandingLoginDialog = ({onCloseDialog}: FormDialogProps) => {
    const navigate = useNavigate();

    const onClose = () => {
        onCloseDialog();
        navigate("/login");
    };

    return (
        <DialogWrapper>
            <LandingLoginForm onSuccessSubmin={onClose} />
        </DialogWrapper>
    );
};
