import {observer} from "mobx-react";
import {Menubar} from "primereact/menubar";
import {useNavigate} from "react-router-dom";
import {PageslInfo} from "../../constants";
import {useRootStore} from "../../store/RootStore";
import {ReactNode} from "react";

type MainMenuProps = {
    className?: string;
    start?: ReactNode;
    end?: ReactNode;
};

const MainMenu = observer(({className, start, end}: MainMenuProps) => {
    const {routeStore} = useRootStore();
    const navigate = useNavigate();

    const menuItems = PageslInfo.filter(item => !item.disabled && item.menuItem)
        .sort((a, b) => b.order - a.order)
        .map(item => ({
            label: item.id,
            path: `/${item.path}`,
            disabled: !!item.menuItemdisabled,
            className: routeStore.isActive(item.path) ? "p-menuitem-active" : "",
            command: () => {
                //TODO: may be create custom hook for this
                if (item.path !== routeStore.activeRoute) {
                    navigate(item.path);
                }
            }
        }));

    return (
        <Menubar
            start={start}
            end={end}
            /*className="header__menu"*/ className={"header__menubar"}
            model={menuItems}
        />
    );
});

export default MainMenu;
