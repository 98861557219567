export const VALIDATION_CONFIG = {
    MIN_PASS_LENGTH: 8,
    MAX_PASSENGERS_TOTAL: 20,
    // PASS_REQUIERED_SYMBOLS: "$!",
    PASS_REQUIERED_SYMBOLS: "!@#$%^&*()-_+=[]`",
    MIN_ID_NUMBER_LENGTH: 8,
    MAX_ID_NUMBER_LENGTH: 12,
    IMAGE_FILE_TYPES: ["image/png", "image/jpeg", "image/jpg"],
    UPLOAD_IMAGE_FILE_SIZE_LIMIT: 2 * 1024 * 1024 // 2MB
};
