type FlightData = {
    departureTime: string;
    arrivalTime: string;
    flightTime: string;
};

export const FLIGHTS_TIME: FlightData[] = [
    {
        departureTime: "06:00 AM",
        arrivalTime: "08:30 AM",
        flightTime: "2h:30m"
    },
    {
        departureTime: "07:15 AM",
        arrivalTime: "10:45 AM",
        flightTime: "3h:30m"
    },
    {
        departureTime: "08:00 AM",
        arrivalTime: "11:00 AM",
        flightTime: "3h:00m"
    },
    {
        departureTime: "09:30 AM",
        arrivalTime: "01:00 PM",
        flightTime: "3h:30m"
    },
    {
        departureTime: "10:00 AM",
        arrivalTime: "03:00 PM",
        flightTime: "5h:00m"
    },
    {
        departureTime: "11:45 AM",
        arrivalTime: "05:15 PM",
        flightTime: "5h:30m"
    },
    {
        departureTime: "12:30 PM",
        arrivalTime: "07:00 PM",
        flightTime: "6h:30m"
    },
    {
        departureTime: "01:00 PM",
        arrivalTime: "02:30 PM",
        flightTime: "1h:30m"
    },
    {
        departureTime: "02:15 PM",
        arrivalTime: "06:45 PM",
        flightTime: "4h:30m"
    },
    {
        departureTime: "03:00 PM",
        arrivalTime: "09:00 PM",
        flightTime: "6h:00m"
    },
    {
        departureTime: "04:30 PM",
        arrivalTime: "11:00 PM",
        flightTime: "6h:30m"
    },
    {
        departureTime: "05:00 PM",
        arrivalTime: "12:30 AM",
        flightTime: "7h:30m"
    },
    {
        departureTime: "06:45 PM",
        arrivalTime: "02:15 AM",
        flightTime: "7h:30m"
    },
    {
        departureTime: "07:00 PM",
        arrivalTime: "02:00 AM",
        flightTime: "7h:00m"
    },
    {
        departureTime: "08:30 PM",
        arrivalTime: "04:30 AM",
        flightTime: "8h:00m"
    },
    {
        departureTime: "09:00 PM",
        arrivalTime: "05:30 AM",
        flightTime: "8h:30m"
    },
    {
        departureTime: "10:15 PM",
        arrivalTime: "07:15 AM",
        flightTime: "9h:00m"
    },
    {
        departureTime: "11:00 PM",
        arrivalTime: "08:30 AM",
        flightTime: "9h:30m"
    },
    {
        departureTime: "12:00 AM",
        arrivalTime: "10:00 AM",
        flightTime: "10h:00m"
    },
    {
        departureTime: "01:30 AM",
        arrivalTime: "11:00 AM",
        flightTime: "9h:30m"
    }
];
