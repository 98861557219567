import React from "react";
import {Button, ButtonProps} from "primereact/button";
import {classNames} from "primereact/utils";

export const CommonButton: React.FC<ButtonProps> = ({className, ...props}) => {
    return <Button {...props} className={classNames(className)} />;
};

export const MainButton: React.FC<ButtonProps> = ({className, ...props}) => {
    return <CommonButton {...props} className={classNames("main-button", className)} />;
};

export const SecondaryButton: React.FC<ButtonProps> = ({className, ...props}) => {
    return <CommonButton {...props} className={classNames("secondary-button", className)} />;
};
