import PageContent from "../../components/MainPageBlocks/PageContent";
import {PagesId} from "../../constants/PAGES_ID";

const pageId = PagesId.Buy;

function BuyPage() {
    return (
        <PageContent pageId={pageId}>
            <h1>Buy page</h1>
        </PageContent>
    );
}

export default BuyPage;
