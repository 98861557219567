import {AIRCRAFTS_DATA} from "../constants/AIRCRAFTS";
import {FLIGHTS_TIME} from "../constants/FLIGHTS_TIME";
import {Random} from "../utils/Random";
import {WaitAction} from "../utils/WaitAction";
import {generateTicketsByDate} from "./generateTicketsByDate";
import {
    ChipData,
    ISearchResult,
    ITicketSearchData,
    TicketData,
    TicketSearchResult
} from "./interfaces";

const TestPrices = ["100.50", "125.50", "130", "150.75", "175", "200.25", "250.75", "280", "300"];
const TestChipsData = ["LAST SEAT", "CHIPEST"];
const TestViewsChipData = [300, 400, 500, 567, 617, 700, 725, 750, 800, 850, 900, 524, 615];

function capitalizeFirstLetter(str: string) {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export async function getTestTicket({
    airportFrom,
    airportTo,
    dates,
    passengers
}: ITicketSearchData): Promise<ISearchResult> {
    await WaitAction.sec(0.5);
    //
    console.log(airportFrom, airportTo, dates, passengers);
    //
    const ticketsByDate = generateTicketsByDate(dates);

    const data: TicketSearchResult[] = [];

    ticketsByDate.forEach(tickets => {
        const ticketSearchResult: TicketSearchResult = {
            sortData: {
                type: "date",
                value: capitalizeFirstLetter(tickets.value)
            },
            data: []
        };

        for (let i = 0; i < tickets.ticketsTotal; i++) {
            //
            const price = `$${Random.from(TestPrices)} USD`;
            const {departureTime, arrivalTime, flightTime} = Random.from(FLIGHTS_TIME);
            // Chips
            const chips: ChipData[] = [
                {
                    type: "views",
                    label: `${Random.from(TestViewsChipData)}`
                }
            ];

            if (Random.bool(0.5)) {
                chips.push({
                    type: "info",
                    label: `${Random.from(TestChipsData)}`
                });
            }

            const ticketData: TicketData = {
                price,
                flightRouteData: {
                    airportFrom,
                    airportTo,
                    departureTime,
                    arrivalTime,
                    flightTime
                },
                chips,
                aircraftData: Random.from(AIRCRAFTS_DATA)
            };
            //

            ticketSearchResult.data.push(ticketData);
        }
        data.push(ticketSearchResult);
    });

    //
    return {status: "success", data};
}
