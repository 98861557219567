import PageContent from "../../components/MainPageBlocks/PageContent";

export function ErrorPage() {
    return (
        <PageContent>
            <div>Error page</div>
        </PageContent>
    );
}

export default ErrorPage;
