import {createContext, useContext} from "react";
import {configure} from "mobx";
import {UserStore} from "./substores/UserStore";
import {RouteStore} from "./substores/RouteStore";
import {SearchTicketsStore} from "./substores/SearchTicketsStore";

configure({enforceActions: "always"});

export class RootStore {
    userStore: UserStore;
    routeStore: RouteStore;
    searchTicketsStore: SearchTicketsStore;

    constructor() {
        this.userStore = new UserStore(this);
        this.routeStore = new RouteStore(this);
        this.searchTicketsStore = new SearchTicketsStore(this);
    }
}

const rootStore = new RootStore();
const RootStoreContext = createContext(rootStore);

export const useRootStore = () => {
    return useContext(RootStoreContext);
};

export default rootStore;
