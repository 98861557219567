import {useState} from "react";
import {FieldValues, SubmitHandler, UseFormSetError} from "react-hook-form";
import {IApiResponse} from "../services/interfaces";

export type ApiCall<T, R> = (data: T) => Promise<R>;

interface UseFormSubmitProps<T extends FieldValues, R> {
    apiCall: ApiCall<T, R>;
    fields: (keyof T)[];
    setError: UseFormSetError<T>;
    onSuccess?: (response: R) => void;
    onError?: (error: any) => void;
}

const useFormSubmit = <T extends FieldValues, R extends IApiResponse>({
    apiCall,
    fields,
    setError,
    onSuccess,
    onError
}: UseFormSubmitProps<T, R>) => {
    const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);

    const onSubmit: SubmitHandler<T> = async data => {
        try {
            const res = await apiCall(data);
            if (res.status === "success") {
                setIsSuccessfullySubmitted(true);
                onSuccess?.(res);
            } else if (res.status === "error") {
                setError("root", {
                    type: "server",
                    message: Array.isArray(res.message) ? res.message[0] : res.message
                });
                onError?.(res.message);
            }
        } catch (e) {
            console.error(e);
            fields.forEach(field => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                setError(field, {
                    type: "server",
                    message: `Something went wrong with ${String(field)}`
                });
            });
            onError?.(e);
        }
    };

    return {onSubmit, isSuccessfullySubmitted};
};

export default useFormSubmit;
