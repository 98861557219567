import {makeAutoObservable} from "mobx";
import {RootStore} from "../RootStore";

export class RouteStore {
    activeRoute = "";
    constructor(protected rootStore: RootStore) {
        makeAutoObservable(this);
    }

    setActiveRoute(route: string) {
        this.activeRoute = route;
    }

    isActive(route: string) {
        return this.activeRoute === route;
    }
}
