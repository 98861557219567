/* eslint-disable max-len */
import {observer} from "mobx-react";
import {useForm} from "react-hook-form";
import {
    EMAIL_VALIDATION_RULES,
    PASSWWORD_SUGGESTIONS_DICTIONARY,
    REQUIRED_PASSWORD_VALIDATION_RULE
} from "../../constants";
import useFormSubmit, {ApiCall} from "../../customHooks/useFormSubmit";
import {usePasswordSuggestions} from "../../customHooks/usePasswordSuggesions";
import {useSafeTranslations} from "../../customHooks/useSafeTranslations";
import {API} from "../../services/ApiService";
import {ILandingLoginData, ILandingLoginResponse} from "../../services/interfaces";
import {MainButton} from "../common/Buttons";
import {
    Field,
    PasswordFieldControlled,
    PasswordSuggestionsFooter,
    TextFieldControlled
} from "../fieldComponents";
import {PhoneField} from "../fields";
import {
    FormContent,
    FormFooter,
    FormHeader,
    Header2Form,
    LandingLoginFormSuccess
} from "../formComponents";
import {useRootStore} from "../../store/RootStore";
import {AnalyticsService} from "../../services/AnalyticsService";

type LandingLoginProps = {
    className?: string;
    onSuccessSubmin?: () => void;
};

export const LandingLoginForm = observer(({onSuccessSubmin}: LandingLoginProps) => {
    const {translate} = useSafeTranslations();
    const {userStore} = useRootStore();
    const {landingLoginFinished} = userStore;

    const defaultValues = {
        loginEmail: "",
        loginPassword: "",
        phone: ""
    };

    const {
        control,
        handleSubmit,
        setError,
        formState: {errors, isSubmitting},
        reset,
        watch
    } = useForm<ILandingLoginData>({
        defaultValues
    });

    const apiCall: ApiCall<ILandingLoginData, ILandingLoginResponse> = data =>
        API.landingLogin(data);

    const {onSubmit, isSuccessfullySubmitted} = useFormSubmit<
        ILandingLoginData,
        ILandingLoginResponse
    >({
        apiCall,
        fields: ["email", "loginPassword", "phone"],
        setError,
        onSuccess: data => {
            userStore.setLandingLoginFinished();
            AnalyticsService.trackLandingLoginFormSubmit();
            onSuccessSubmin?.();
            reset();
        }
    });

    const loginPasswordValue = watch("loginPassword") || "";
    const loginPasswordRulles = usePasswordSuggestions(
        loginPasswordValue,
        PASSWWORD_SUGGESTIONS_DICTIONARY
    );

    const showSuccessBlock = isSuccessfullySubmitted || landingLoginFinished;

    return (
        <form className="form form--landing-login-form" onSubmit={handleSubmit(onSubmit)}>
            {showSuccessBlock ? (
                <LandingLoginFormSuccess />
            ) : (
                <>
                    <FormHeader>
                        <Header2Form>{translate("login-form-header-text")}</Header2Form>
                    </FormHeader>
                    <FormContent className={"pt-3"}>
                        {/* <LoginEmailField
                            name={"loginEmail"}
                            control={control}
                            errors={errors.loginEmail}
                            disabled={isSubmitting}
                        /> */}

                        <TextFieldControlled
                            control={control}
                            name={"email"}
                            disabled={isSubmitting}
                            placeholder={translate("email-text")}
                            type={"text"}
                            rulles={{...EMAIL_VALIDATION_RULES}}
                            errors={errors.email}
                        />

                        <PasswordFieldControlled
                            name={"loginPassword"}
                            placeholder={translate("password-text")}
                            disabled={isSubmitting}
                            control={control}
                            rulles={REQUIRED_PASSWORD_VALIDATION_RULE}
                            errors={errors.loginPassword}
                            footer={<PasswordSuggestionsFooter rules={loginPasswordRulles} />}
                        />

                        <PhoneField
                            control={control}
                            errors={errors.phone}
                            disabled={isSubmitting}
                        />
                    </FormContent>

                    <FormFooter>
                        <Field>
                            <MainButton
                                type={"submit"}
                                label={translate("login-text")}
                                className={"main-button--w-full"}
                            />
                        </Field>
                    </FormFooter>
                </>
            )}
        </form>
    );
});
