import {observer} from "mobx-react";
import {TicketsSearchForm} from "../../components/forms/TicketsSearchForm";
import PageContent from "../../components/MainPageBlocks/PageContent";
import {PagesId} from "../../constants/PAGES_ID";
import {useRootStore} from "../../store/RootStore";
import {TicketCardCollection} from "../../components/cards/TicketCardCollection";
import {useDialogContext} from "../../customHooks/useDialogContext";
import {useEffect} from "react";
import {LandingLoginDialog} from "../../components/dialogs";

const pageId = PagesId.Search;

const SearchPage = observer(() => {
    const {openDialog, closeDialog} = useDialogContext();
    const {userStore} = useRootStore();

    const {
        searchTicketsStore: {searchResult}
    } = useRootStore();

    useEffect(() => {
        openDialog(LandingLoginDialog, {onCloseDialog: closeDialog});
    }, []);

    return (
        <PageContent pageId={pageId}>
            <TicketsSearchForm />

            {searchResult && searchResult.length ? (
                <TicketCardCollection
                    className={"blur-effect"}
                    ticketsSearchResult={searchResult}
                />
            ) : null}

            {/* <LoginForm />
              <NewPasswordForm />
              <ResetPasswordForm />
              <RegisterFormGeneralInfo />
              <RegisterFormPersonalIDUpload />
              <AccountTabs /> */}
        </PageContent>
    );
});

export default SearchPage;
