import {ReactNode} from "react";
import {Navigate} from "react-router-dom";
import {observer} from "mobx-react";
import {useRootStore} from "../../store/RootStore";

interface PrivateRouteProps {
    children: ReactNode;
    redirectTo?: string;
}

const PrivateRoute = observer(({children, redirectTo = "/login"}: PrivateRouteProps) => {
    const {userStore} = useRootStore();
    return <>{userStore.isLoggedIn ? children : <Navigate to={redirectTo} />}</>;
});

export default PrivateRoute;
