import React, {ReactNode} from "react";

interface MainProps {
    children: ReactNode;
}

const Main: React.FC<MainProps> = ({children}) => {
    return (
        <main className="w-full flex-grow p-3 bg-gray-100">
            <div className="grid grid-nogutter justify-content-center align-items-center">
                <div className="main-content col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">
                    {children}
                </div>
            </div>
        </main>
    );
};

export default Main;
