import {classNames} from "primereact/utils";
//
type HeaderProps = {
    children: React.ReactNode;
    className?: string;
};
//
export const Header2 = ({children, className}: HeaderProps) => (
    <h2 className={classNames("header2", className)}>{children}</h2>
);
export const Header3 = ({children, className}: HeaderProps) => (
    <h3 className={classNames("header3", className)}>{children}</h3>
);

export const Header4 = ({children, className}: HeaderProps) => (
    <h4 className={classNames("header4", className)}>{children}</h4>
);

export const Header5 = ({children, className}: HeaderProps) => (
    <h5 className={classNames("header5", className)}>{children}</h5>
);

export const ContentB1 = ({children, className}: HeaderProps) => (
    <div className={classNames("content-b1", className)}>{children}</div>
);
