/* eslint-disable react-hooks/rules-of-hooks */
import {useTranslation} from "react-i18next";

export const useSafeTranslations = () => {
    const {t} = useTranslation();

    const translate = (key: string, vars?: object) => {
        return vars ? t(key, vars) : t(key);
    };

    return {translate};
};
