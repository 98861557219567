import {action, makeObservable, observable} from "mobx";
import {RootStore} from "../RootStore";
import {API} from "../../services/ApiService";
import {ITicketSearchData} from "../../services/interfaces";
import {DEFAULT_AIRPORT_FROM} from "../../constants/AIRPORTS";

export class UserStore {
    public isLoggedIn = false;
    public isAuthError = false;

    public landingLoginFinished = false;
    //

    constructor(protected rootStore: RootStore) {
        makeObservable(this, {
            isLoggedIn: observable,
            isAuthError: observable,
            landingLoginFinished: observable,
            setIsAuthError: action,
            setLandingLoginFinished: action
        });
    }

    async checkAuth() {
        let isLoggedIn = false;
        let isAuthError = false;
        try {
            isLoggedIn = await API.checkAuth();
            isAuthError = false;
        } catch (error) {
            isLoggedIn = false;
            isAuthError = true;
            throw new Error("UserStore: checkAuth error");
        } finally {
            this.setLoggedIn(isLoggedIn);
            this.setIsAuthError(isAuthError);
        }
    }

    public setLoggedIn(loggedIn: boolean) {
        this.isLoggedIn = loggedIn;
    }

    public setIsAuthError(isAuthError: boolean) {
        this.isAuthError = isAuthError;
    }

    setLandingLoginFinished() {
        this.landingLoginFinished = true;
    }
}
