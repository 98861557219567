export const AnalyticsService = {
    trackLandingLoginFormSubmit: () => {
        //GA
        if (gtag) {
            gtag("event", "generate_lead", {});
        }
        //FB Pixel
        if (fbq) {
            fbq("track", "Lead", {
                content_name: "landing_login_form",
                content_category: "user_login"
            });
        }
    }
};
