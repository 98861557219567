export function generateTicketsByDate(dates?: [Date | null, Date | null]) {
    // Генерируем случайное количество билетов от 7 до 15
    const totalTickets = Math.floor(Math.random() * (20 - 7 + 1)) + 7;

    // Получаем startDate и endDate из входного объекта
    const startDate = dates && dates[0] ? new Date(dates[0]) : new Date();
    let endDate = dates && dates[1] ? new Date(dates[1]) : null;

    // Если endDate задан и меньше startDate, делаем endDate = startDate + 3 дня
    if (endDate && endDate < startDate) {
        endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 3);
    }

    // Если endDate не задан, делаем его startDate + 3 дня
    if (!endDate) {
        endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 3);
    }

    // Если разница в днях между startDate и endDate больше 4, ограничиваем диапазон 4 днями
    const maxDays = 4;
    const daysDiff = Math.min(
        Math.floor((+endDate - +startDate) / (1000 * 60 * 60 * 24)) + 1,
        maxDays
    );

    // Функция для форматирования даты в строку "13 august 2024"
    const formatDate = (date: Date): string => {
        const options: Intl.DateTimeFormatOptions = {
            day: "numeric",
            month: "long",
            year: "numeric"
        };
        return date.toLocaleDateString("en-US", options).toLowerCase();
    };

    // Массив объектов с билетами для каждого дня
    const ticketsByDay: {value: string; ticketsTotal: number}[] = [];

    // Если startDate и endDate совпадают, все билеты идут на один день
    if (daysDiff === 1) {
        ticketsByDay.push({
            value: formatDate(startDate),
            ticketsTotal: totalTickets
        });
    } else {
        let remainingTickets = totalTickets;

        for (let i = 0; i < daysDiff; i++) {
            const currentDate = new Date(startDate);
            currentDate.setDate(startDate.getDate() + i);

            // Если это последний день, все оставшиеся билеты идут на этот день
            const ticketsForDay =
                i === daysDiff - 1
                    ? remainingTickets
                    : Math.floor(Math.random() * (remainingTickets - (daysDiff - i - 1))) + 1;

            remainingTickets -= ticketsForDay;

            ticketsByDay.push({
                value: formatDate(currentDate),
                ticketsTotal: ticketsForDay
            });
        }
    }

    return ticketsByDay;
}
