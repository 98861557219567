import React, {useEffect, useRef} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import useFormSubmit, {ApiCall} from "../../customHooks/useFormSubmit";
import {useSafeTranslations} from "../../customHooks/useSafeTranslations";
import {API} from "../../services/ApiService";
import {IProfilePersonalID, IProfilePersonalIDResponse} from "../../services/interfaces";
import {MainButton, SecondaryButton} from "../common/Buttons";
import {
    ContentB1Form,
    FormContent,
    FormFooter,
    FormHeader,
    FormProvideLaterLink,
    Header2Form
} from "../formComponents";
import {UploadImageControlled} from "../fieldComponents";
import {UPLOAD_ID_IMAGE_VALIDATION_RULE} from "../../constants";
import {FileUpload} from "primereact/fileupload";
import {IDialogFormProps} from "./interfaces";
import {classNames} from "primereact/utils";

interface IUploadImage {
    image: File[] | null;
}

const RegisterFormPersonalIDUpload: React.FC<IDialogFormProps> = ({
    onCancel,
    onSuccess,
    className,
    CloseComponent = FormProvideLaterLink
}) => {
    const defaultValues = {
        image: null
    };

    const {translate} = useSafeTranslations();
    const {
        control,
        handleSubmit,
        setError,
        clearErrors,
        formState: {errors, isSubmitting, isValid, isDirty},
        reset
    } = useForm<IUploadImage>({
        defaultValues
    });

    // const apiCall: ApiCall<IProfilePersonalID, IProfilePersonalIDResponse> = data =>
    //     API.setPersonalID(data);

    // const {onSubmit, isSuccessfullySubmitted} = useFormSubmit<
    //     IProfilePersonalID,
    //     IProfilePersonalIDResponse
    // >({
    //     apiCall,
    //     fields: ["idNumber"],
    //     setError,
    //     onSuccess: data => {
    //         console.log("onSuccess", data);
    //         reset();
    //     }
    // });

    // Функция для обработки отправки данных
    const onSubmit: SubmitHandler<IUploadImage> = data => {
        if (data.image && data.image.length > 0) {
            const formData = new FormData();
            formData.append("image", data.image[0]);

            // Отправляем данные на сервер
            fetch("/upload", {
                method: "POST",
                body: formData
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error("Ошибка при загрузке файла");
                    }
                    return response.json();
                })
                .then(result => {
                    console.log("Успешная загрузка:", result);
                })
                .catch(error => {
                    console.error("Ошибка загрузки:", error);
                });
        }
    };

    const validateFile = (value: File[] | null) => {
        if (value && value.length > 0) {
            const file = value[0];
            const validTypes = ["image/jpeg", "image/png", "image/gif"];
            const maxSize = 2 * 1024 * 1024; // 2MB

            if (!validTypes.includes(file.type)) {
                setError("image", {
                    type: "manual",
                    message: "Недопустимый тип файла. Разрешены только JPEG, PNG и GIF."
                });
                return false;
            }

            if (file.size > maxSize) {
                setError("image", {
                    type: "manual",
                    message: "Файл слишком большой. Максимальный размер 2MB."
                });
                return false;
            }

            clearErrors("image");
            return true;
        } else {
            setError("image", {
                type: "manual",
                message: "Пожалуйста, выберите файл для загрузки."
            });
            return false;
        }
    };

    const onClickCancel = () => onCancel && onCancel();

    return (
        <>
            <form
                className={classNames("form form--register-personal-id-upload", className)}
                onSubmit={handleSubmit(onSubmit)}
            >
                <FormHeader>
                    {CloseComponent && <CloseComponent disabled={isSubmitting} />}
                    <Header2Form>{translate("register-form-header-text")}</Header2Form>
                    <ContentB1Form>{translate("personal-id-information")}</ContentB1Form>
                </FormHeader>
                <FormContent className={"mt-5"}>
                    <UploadImageControlled
                        rulles={{...UPLOAD_ID_IMAGE_VALIDATION_RULE}}
                        name={"image"}
                        control={control}
                        errors={errors.image}
                    />
                </FormContent>
                <FormFooter className={"form__footer--row-directed justify-content-between"}>
                    <SecondaryButton
                        type={"button"}
                        className={"secondary-button--w-full"}
                        label={translate("back-button-text")}
                        disabled={isSubmitting}
                        onClick={onClickCancel}
                    />
                    <MainButton
                        className={"main-button--w-full"}
                        type="submit"
                        label={translate("next-button-text")}
                        disabled={isSubmitting}
                    />
                </FormFooter>
            </form>
        </>
    );
};

export default RegisterFormPersonalIDUpload;
