import {observer} from "mobx-react";
import {ReactNode} from "react";
import {useRootStore} from "../../store/RootStore";
import {Button} from "primereact/button";
import "./header.scss";
import "./header-menubar.scss";
import {useNavigate} from "react-router-dom";
import {useSafeTranslations} from "../../customHooks/useSafeTranslations";
import {MainButton} from "../common/Buttons";
import MainMenu from "../MainMenu";

interface HeaderProps {
    logoComponent: ReactNode;
    menuComponent: ReactNode;
    userComponent?: ReactNode;
}

const Avatar = () => {
    return <div>Avatar</div>;
};

const LoginButton = () => {
    const {translate} = useSafeTranslations();
    const navigate = useNavigate();

    const onLoginClick = () => {
        navigate("/login");
    };

    return (
        <div className="flex justify-content-center align-items-center">
            <Button
                onClick={onLoginClick}
                label={translate("main-login-btn-text")}
                className="main-button login-button"
            />
        </div>
    );
};

const Header = observer(({logoComponent, menuComponent, userComponent}: HeaderProps) => {
    const {userStore} = useRootStore();
    const {isLoggedIn} = userStore;

    return (
        <header className="w-full flex justify-content-between align-items-center header">
            <MainMenu
                start={logoComponent}
                end={
                    <>{userComponent ? userComponent : isLoggedIn ? <Avatar /> : <LoginButton />}</>
                }
            />
            {/* <div className={"col-fixed  p-0 flex-order-1 sm:flex-order-0"}>{logoComponent}</div>
            <div className={"col-8 sm:col-8 p-0 flex-order-0 sm:flex-order-1"}>{menuComponent}</div>
            <div className={"col-2 p-0 flex justify-content-end flex-order-2"}>
                {userComponent ? userComponent : isLoggedIn ? <Avatar /> : <LoginButton />}
            </div> */}
        </header>
    );
});

export default Header;
