import {observer} from "mobx-react";
import {ResetPasswordForm} from "../../components/forms/ResetPasswordForm";
import PageContent from "../../components/MainPageBlocks/PageContent";
import {PagesId} from "../../constants/PAGES_ID";

const pageId = PagesId.Reset;

const ResetPage = observer(() => {
    return (
        <PageContent pageId={pageId}>
            <ResetPasswordForm />
        </PageContent>
    );
});

export default ResetPage;
